import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import events from "./event.module";
import members from "./member.module";
import organisations from "./organisation.module";
import groups from "./groups.module";
import tickets from "./ticket.module";
import promocode from "./promocode.module";
import campaignModule from "./campaignModule";
import campaign from "./campaign.module";
import reportbug from "./reportbug.module";
import payments from "./payments.module";
import storeModule from "./store.module";
import user_management from "./user_mamagement.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    events,
    members,
    organisations,
    groups,
    tickets,
    promocode,
    campaignModule,
    campaign,
    reportbug,
    payments,
    storeModule,
    user_management
  }
});
