import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config";

//if loggedin user unauthenticate then redirect on login.
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    JwtService.destroyToken();
    location.reload()
  }
  return Promise.reject(error)
});
//end unauthentiation code
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  setHeaderVerifyEmail() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  getIp(){
    return Vue.axios.get("https://dev.payangel.com/getClientIP");
  },
  get(resource, slug = "") {
    var url = `${resource}`;
    if(slug){
      url = `${resource}/${slug}`
    } 
    return Vue.axios.get(`${url}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;


export const OrganisationService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_MEMBERSHIP_DOMAIN;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  get(resource,slug) {
    return ApiService.get(`${resource}`, slug);
  },
  create(resource,params) {
    return ApiService.post(`${resource}`, params);
  },
  update(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },
  patch(resource, params) {
    return ApiService.patch(`${resource}`,params);
  },
  destroy(resource) {
    return ApiService.delete(`${resource}`);
  }
};

export const GroupService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_MEMBERSHIP_DOMAIN;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  get(resource,slug) {
    return ApiService.get(`${resource}`, slug);
  },
  create(resource,params) {
    return Vue.axios.post(`${resource}`, params);
    //ApiService.post(`${resource}`, params);
  },
  patch(resource, params) {
    return ApiService.patch(`${resource}`,params);
  },
  destroy(resource) {
    return ApiService.delete(`${resource}`);
  }
};

export const MemberService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_MEMBERSHIP_DOMAIN;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  get(resource,slug) {
    return ApiService.get(`${resource}`, slug);
  },
  create(resource,params) {
    return ApiService.post(`${resource}`, params);
  },
  patch(resource, params) {
    return ApiService.patch(`${resource}`,params);
  },
  destroy(resource) {
    return ApiService.delete(`${resource}`);
  },
  downloadCsv(resource){
    
    let url = `${process.env.VUE_APP_MEMBERSHIP_DOMAIN}${resource}`;
    
    // Set up headers
    const headers = {
      'Authorization': `Bearer ${JwtService.getToken()}`, // ACCESS_TOKEN
    };

    // Make the Axios request
    axios.get(url, {
      headers: headers,
      responseType: 'blob', // Important: Set responseType to 'blob' to handle binary data
    })
    .then(response => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'members.xlsx'); // or any other extension
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => console.error('Axios error:', error));
    
  }
};

export const EventService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_EVENTS_DOMAIN;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  get(resource,slug) {
    if(slug){
      return ApiService.get(`${resource}`, `${slug}`);  
    }else{
      //return ApiService.get(`${resource}`, slug);
      return axios.get(`${resource}`, slug);
    }
  },
  create(resource,params) {
    return ApiService.post(`${resource}`, params);
  },
  patch(resource, params) {
    return ApiService.patch(`${resource}`,params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  withoutTokenPatch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },
  destroy(resource) {
    return axios.delete(`${resource}`);
  },
  getPromoCode(resource,slug) {
    return axios.get(`${resource}${slug}`);
  },
};

export const CampaingService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_CAMPAIGN_DOMAIN;
  },
  initBase() { //Created for get uername First name and last name
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_BASEURL;
  },
  setHeader() {
    //Vue.axios.defaults.headers.common["Authorization"] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyM2EyOTI3Yi1hZDQzLTRjM2EtYjRlOS01ZmYyNWU1M2ExOWQiLCJ0eXBlIjoiYnVzaW5lc3MiLCJkYXRhIjoiMjhkMTI4M2UwZWY4NmY3MjYwZjFlNTg4Y2I5MjMxNWE3ODEwIiwiZXh0cmEiOnsic3RhdHVzIjoxfSwiaWF0IjoxNjYwNzE5NDkwLCJleHAiOjE2NjA4MDU4OTB9.mq14G7aJzqTIfomMzEB-yKRlNgXu292fmEtgeCmcqSI`;
  },
  setCampaignHeader() {
      delete Vue.axios.defaults.headers.common["Authorization"];
      //Vue.axios.defaults.headers.post['X-UserId'] = userId;
  },
  get(resource, slug) {
    // let response = axios.get(`${resource}` , { headers: {"Access-Control-Allow-Origin" : '*'} })
    // console.log(response)
    // return response;
    if(slug){
      return axios.get(`${resource}${slug}`);
    }else{
      return axios.get(`${resource}`);
    }
  },
  create(resource,params) {
    // const headers = {
    //   headers: {
    //     'Accept': '*/*',
    //     'Host': 'crowdfunding.standapp.com'
    //   }
    // }
    //, headers
    return axios.post(`${resource}`, params);
  },
  patch(resource, params) {
    return ApiService.patch(`${resource}`,params);
  },
  destroy(resource, params) {
    return axios.delete(`${resource}`, {data: params});
  },
  getPromoCode(resource,slug) {
    return axios.get(`${resource}${slug}`);
  },
}

export const ReportBugServie = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_EVENTS_DOMAIN;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  get(resource,slug) {
    return ApiService.get(`${resource}`, slug);
  },
  create(resource,params) {
    return ApiService.post(`${resource}`, params);
  },
};

export const PaymentServie = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_STANDAPP;// 'https://stand-app.com/api/v1/';
  },
  initStage() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_STANDAPP_stage;// 'https://stand-app.com/api/v1/';
  },
  create(resource,params) {
    return axios.post(`${resource}`, params);
  },
  get(resource,slug) {
    return axios.get(`${resource}`, slug);
  },
};

export const PayAngelServie = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_PAYANGEL_URL;
  },
  create(resource,params) {
    return ApiService.post(`${resource}`, params);
  },
};

export const RefundServie = {
  init() {
    // Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = process.env.VUE_APP_TICKET_REFUND;// 'https://stand-app.com/api/v1/';
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  create(resource,params) {
    return axios.post(
      `${process.env.VUE_APP_TICKET_REFUND}${resource}/`,
      params
    );
    // return axios.post(`${resource}`, params, {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    //     "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    //     "accept": "application/json, text/plain, */*",
    //     "accept-language": "en-GB,en-US;q=0.9,en;q=0.8",
    //     "cache-control": "no-cache",
    //     "content-type": "application/json",
    //     "pragma": "no-cache",
    //     "sec-ch-ua": "\"Google Chrome\";v=\"113\", \"Chromium\";v=\"113\", \"Not-A.Brand\";v=\"24\"",
    //     "sec-ch-ua-mobile": "?0",
    //     "sec-ch-ua-platform": "\"macOS\""
    //   }
    // });
  },
};

export const StoreService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_BASEURL;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  get(resource,slug) {
    return ApiService.get(`${resource}`, slug);
  },
  create(resource,params) {
    return Vue.axios.post(`${resource}`, params);
    //ApiService.post(`${resource}`, params);
  },
  patch(resource, params) {
    return ApiService.patch(`${resource}`,params);
  },
  post(resource, params) {
    return ApiService.post(`${resource}`,params);
  },
  destroy(resource) {
    return ApiService.delete(`${resource}`);
  }
};

export const PayoutService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_BASEURL;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  get(resource,slug) {
    return ApiService.get(`${resource}`, slug);
  },
  create(resource,params) {
    return Vue.axios.post(`${resource}`, params);
    //ApiService.post(`${resource}`, params);
  },
  patch(resource, params) {
    return ApiService.patch(`${resource}`,params);
  },
  post(resource, params) {
    return ApiService.post(`${resource}`,params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  destroy(resource) {
    return ApiService.delete(`${resource}`);
  }
};

export const UserManagementService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL= process.env.VUE_APP_BASEURL;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  get(resource,slug) {
    return ApiService.get(`${resource}`, slug);
  },
  create(resource,params) {
    return Vue.axios.post(`${resource}`, params);
    //ApiService.post(`${resource}`, params);
  },
  patch(resource, params) {
    return ApiService.patch(`${resource}`,params);
  },
  post(resource, params) {
    return ApiService.post(`${resource}`,params);
  },
  destroy(resource) {
    return ApiService.delete(`${resource}`);
  }
};
